<template>
    <div class="dashboard-search-card-container "  @keypress="keyPress($event)"   >
      <le-card width="100%" height="150px" >
        <template>
          <div class="container">
            <div class="card-title">搜索</div>
            <div class="search-box">
              <div class="time-box">
              <!-- <div class="time-title">起止时间：</div>
              <a-month-picker v-model="month" placeholder="选择月份" class="input-month"></a-month-picker> -->
              <!-- <a-input v-model="search" placeholder="请输入主播昵称或uid" class="input-search" :maxLength="20" :allowClear="true" @change="changeSearch()"  /> -->

              <a-input-group compact style="width: 260px;">
                <a-select  v-model="search_by" style="width: 80px;" >
                  <a-select-option value="uid">
                    UID
                  </a-select-option>
                  <a-select-option value="nickname">
                    昵称
                  </a-select-option>
                  <a-select-option value="roomid">
                    房间号
                  </a-select-option>
                </a-select>
                <a-input style="width: 180px" placeholder="请输入" v-model="search" />
              </a-input-group>

              <!-- <a-input style="width: 50%" placeholder="uid" v-model="search" :allowClear="true" /> -->



                <!-- 提交状态:&nbsp;&nbsp;
                <a-select style="width: 150px;" defaultValue="-1"  v-model="commit_status" >
                  <a-select-option value="-1">
                    全部
                  </a-select-option>
                  <a-select-option value="0">
                    未提交
                  </a-select-option>
                  <a-select-option value="1">
                    已提交
                  </a-select-option>
                </a-select>
&nbsp;&nbsp;&nbsp;&nbsp;
                评级状态:&nbsp;&nbsp;
                <a-select style="width: 150px;"  v-model="grade" >
                  <a-select-option value="-1">
                    全部
                  </a-select-option>
                  <a-select-option value="0">
                    未平级
                  </a-select-option>
                  <a-select-option value="1">c级</a-select-option>
                  <a-select-option value="2">b级</a-select-option>
                  <a-select-option value="3">a级</a-select-option>
                  <a-select-option value="4">s级</a-select-option>
                  <a-select-option value="5">带评级</a-select-option>
                </a-select>
&nbsp;&nbsp;&nbsp;&nbsp;
                扶持状态:&nbsp;&nbsp;
                <a-select style="width: 150px;"  v-model="support_status">
                  <a-select-option value="-1">
                    全部
                  </a-select-option>
                  <a-select-option value="0">
                    未扶持
                  </a-select-option>
                  <a-select-option value="1">
                    扶持中
                  </a-select-option>
                  <a-select-option value="2">
                    已结束
                  </a-select-option>
                </a-select> -->

              <div class="button-box">
                <le-button   height="35px"   @btnClick="resetClick" txt="重置"  background="linear-gradient(-10deg, #ddd, #aaa)" />
                <le-button   height="35px"   @btnClick="searchClick" style="margin-left: 20px;" />
              </div>
              </div>

            </div>
          </div>
        </template>
      </le-card>
    </div>
</template>

<script>
import moment from 'moment'
import tool from '@/utils/tool'
export default {
  components: { 
  },
  data() {
    return {
      search_by:'uid',
      word:'',
      month:null,
      search:'',
      commit_status:'-1', //提交状态 0未提交 1已经提交 -1全部
      grade:'-1', //级别 4:s级 3:a级 2:b级 1:c级 0:未平级 5:带评级 -1全部
      support_status:'-1' //	扶持状态 0 未扶持 1扶持中 2已结束 -1全部
    };
  },
  mounted(){
    
  },
  methods: {
    //监听回车键
    keyPress(e){
      if(e.charCode==13){
        this.searchClick()
      }
    },
    resetClick(){
      this.search_by='uid'
      this.search=''
      this.commit_status='-1'
      this.grade='-1'
      this.support_status='-1'

      let {
      commit_status, //提交状态 0未提交 1已经提交 -1全部
      grade, //级别 4:s级 3:a级 2:b级 1:c级 0:未平级 5:带评级 -1全部
      support_status
      }=this
      let param={
          commit_status, //提交状态 0未提交 1已经提交 -1全部
          grade, //级别 4:s级 3:a级 2:b级 1:c级 0:未平级 5:带评级 -1全部
          support_status,
          word:''
      }
      // tool.$emit('onSetNull', param)
      // tool.$emit('search', param)
    },
    searchClick(){
      let {
      commit_status, //提交状态 0未提交 1已经提交 -1全部
      grade, //级别 4:s级 3:a级 2:b级 1:c级 0:未平级 5:带评级 -1全部
      search_by,
      support_status
      }=this

      let param={
          commit_status, //提交状态 0未提交 1已经提交 -1全部
          grade, //级别 4:s级 3:a级 2:b级 1:c级 0:未平级 5:带评级 -1全部
          support_status,
          search_by,
          word:this.search
      }
      console.log('param',param)
      // if(this.search){
      //   if(this.model==0){
      //     param.nickname=this.search
      //   }else if(this.model==1){
      //     param.uid=this.search
      //   }else{
      //     param.room_id=this.search
      //   }
      // }
      tool.$emit('search', param)
    }
  }
};
</script>

<style lang="less" scoped>
.dashboard-search-card-container {
.container{
  position: relative;
  .title{
    padding-top: 20px;
    margin-left: 30px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .search-box{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .time-box{
      display: flex;
      align-items: center;
      margin-left: 30px;
      .time-title{
        margin-left: 30px;
        font-size: 24px;
        color: #333333;
      }
      .input-month{
        width: 200px;
      }
      .input-search{
        width:200px;
        margin-left:10px
      }
      .button-box{
        margin-left: 20px;
        display: flex;
      }
    }

  }
}
}

@media screen and(max-width:750px) {
  .dashboard-search-card-container{
    .container{
  position: relative;
  .title{
    padding-top: 10px;
    margin-left: 20px;
    font-size: 20px;
  }

  .search-box{
    margin-top: 10px;
    .time-box{
      flex-wrap: wrap;
      .time-title{
        margin-left: 20px;
        font-size: 16px;
      }
      .input-month{
        width: 150px;
      }
      .input-search{
        width:150px;
        margin-left:20px;
      }
          .button-box{
      margin-left: 20px;
      margin-top: 5px;
    }
    }
  }
  }
  }
}




</style>
