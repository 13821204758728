<template>
    <div class="dashboard-table-card-container ">
      <le-card width="100%" style="padding-bottom:20px" >
        <template>
          <div class="container">
            <div class="card-title">扶持管理 
            </div>
            <!-- <le-button txt="导出" class="le-button" @btnClick="rePull()" /> -->
            <div class="table-box">
                <a-table 
                :columns="columns" 
                :data-source="dataSource"
                :loading="loading"
                :pagination="pageOptions"
                @change="handleTableChange"
                size="middle" >

                <div slot="uname"  slot-scope="row">
                  <a class="name-box" :href="'https://space.bilibili.com/'+row.uid" target="_blank" rel="noopener noreferrer">
                    <!-- <div class="avatar"  >
                      <img :src="row.face_url" alt="" srcset="">
                      </div> -->
                    <div class="name-uid">
                      <div>{{row.nickname}}</div>
                      <div>{{row.uid}}</div>
                    </div>
                  </a>
                </div>

                <span slot="commit_status" slot-scope="row">
                  <p v-if="row.commit_status==0" >未提交</p>
                  <p v-else >已提交</p>
                </span>

                <span slot="grade" slot-scope="row">
                  <p v-if="row.grade==0" >未平级</p>
                  <p v-else-if="row.grade==1" >C级</p>
                  <p v-else-if="row.grade==2" >B级</p>
                  <p v-else-if="row.grade==3" >A级</p>
                  <p v-else-if="row.grade==4" >S级</p>
                  <p v-else >带评级</p>
                </span>


                <span slot="support_status" slot-scope="row">
                  <p v-if="row.support_status==0" >未扶持</p>
                  <p v-else-if="row.support_status==1" >扶持中</p>
                  <p v-else >已结束</p>
                </span>

                  <div slot="identity" slot-scope="row" >
                      <div>{{row==1?'经纪人':'工会' }}</div>
                  </div>

              </a-table>
            </div>
          </div>
        </template>
      </le-card>
    </div>
</template>

<script>

const columns = [
  // {
  //   title: '主播信息',
  //   scopedSlots: { customRender: "uname" },
  // },
  {
    title: '主播UID',
    dataIndex: 'uid'
  },
  {
    title: '主播昵称',
    dataIndex: 'nickname'
  },
  {
    title: '房间号',
    dataIndex: 'roomid'
  },
    {
    title: '开播最长一级分区',
    dataIndex: 'area'
  },
    {
    title: '入会时间',
    dataIndex: 'entry_time',
    sorter: (a, b) => a.entry_time - b.entry_time,
  },
   {
    title: '提交时间',
    dataIndex: 'commit_time',
    sorter: (a, b) => a.commit_time - b.commit_time,
  },
   {
    title: '提交状态',
    // dataIndex: 'commit_status',
    scopedSlots: { customRender: "commit_status" },
  },
  {
    title: '评级状态',
    // dataIndex: 'grade',
    scopedSlots: { customRender: "grade" },
  },

  {
    title: '扶持状态',
    // dataIndex: 'support_status',
    scopedSlots: { customRender: "support_status" },
  },

  {
    title: '扶持周期',
    width:200,
    dataIndex: 'support_detail',
  }
];

import {MixinList} from '@/common/mixin';
export default {
  data() {
    return {
      columns,
      // dataUrl:'v1/user/task/supportLog',
      dataUrl:'v2/supportManagement/get?search_by=uid',
      delUrl:'admin/layout/del',
      uid:'',
      editRow:{},

    };
  },
  computed: {
  },
  created(){
    this.isGet=false
  },


  mixins:[MixinList],
  mounted(){
    // if(this.$route.query.temp_uid){
    //   this.dataUrl='v1/user/list?temp_uid='
    // }
    // console.log(this.$route.query.temp_uid)

  },
  methods: {
    clickEdit(row){
      this.editRow=row
      this.visible = true;
    },
    handleOk(){
      this.visible = false;
      this.getData()
    },
    handleCancel(){
      this.visible = false;
      this.getData()
    },
    clickGoto(row){
       let routeData = this.$router.resolve({ path: '/index/dashboard', query: {  temp_uid: row.uid } });
        window.open(routeData.href, '_blank'); 
      // window.open('https://www.bilibililink.com/index/dashboard?temp_uid='+row.uid)
      // window.open('http://localhost:8080/index/dashboard?temp_uid='+row.uid)
    },
    showLog(uid){
      console.log('uid',uid)
      this.uid=uid
      this.visibleLog=true
    },
    // rePull(){
    //     this.$api.get(`v1/user/sync`).then((res) => {
    //       if(res.code==200){
    //       this.dataSource=res.data.list.map((d) => {
    //         d.key = d.created_at
    //         return d
    //       })
    //       }else{
    //       this.$message.error(res.message)
    //       }
    //     });
    // }
  }
};
</script>

<style lang="less" scoped>
.dashboard-table-card-container {
.container{
  position: relative;
  .title{
    padding-top: 20px;
    margin-left: 30px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: flex-end;
    .num-box{
      font-size: 18px;
      margin-left: 11px;
      line-height: 32px;
    }
  }
  .le-button{
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .table-box{
    margin: 40px 30px;
  }
}
}

.login{
  cursor: pointer;
  &:hover{
    color: #008DF0;
  }
}

.name-box{
  display: flex;
  .avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    }
  }
}

@media screen and(max-width:750px) {
 .dashboard-table-card-container {
.container{
  .title{
    padding-top: 10px;
    margin-left: 20px;
    font-size: 20px;
    .num-box{
      font-size: 12px;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .le-button{
    position: absolute;
    top: 10px;
    right: 20px;
    width: 50px!important;
  }

  .table-box{
    margin: 20px;
    overflow-x:scroll ;
  }
}
}
}

</style>
